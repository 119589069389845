<template>
	<v-container fluid class="text-justify" style="max-width: 1200px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('policies.privacy') }}</span>
		</div>

		<v-divider class="my-2" />
		<v-btn text :to="{ name: 'Policies' }">
			{{ $t('policies.goBack') }}
		</v-btn>
		<div class="my-10">
			<h2 class="terms-header">La versión corta</h2>
			<p>
				Sólo recopilamos la información que eliges proporcionarnos y la procesamos con tu consentimiento o con otra base legal; solo requerimos la
				cantidad mínima de información personal necesaria para cumplir con el propósito de tu interacción con nosotros; no la vendemos a terceros; y
				sólo la usamos como se describe en esta Declaración de privacidad. Como operamos principalmente dentro de la UE cumplimos con el Reglamento
				general de protección de datos (GDPR). No importa dónde te encuentres, dónde vivas o cuál sea tu ciudadanía, brindamos el mismo estándar de
				protección de privacidad a todos nuestros usuarios en todo el mundo, independientemente de su país de origen o ubicación.
			</p>
			<p>Por supuesto, la versión corta no lo dice todo, ¡así que sigue leyendo para obtener más detalles!</p>
		</div>
		<div class="my-10">
			<h2 class="terms-header">Resumen</h2>
			<v-simple-table class="mx-auto my-3">
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-center">Sección</th>
							<th class="text-center">¿Qué puedes encontrar allí?</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<a style="text-decoration: none" href="#what-information-OverSOS-collects-and-why">Qué información recopila OverSOS y por qué</a>
							</td>
							<td>
								OverSOS recopila información básica de los visitantes de nuestro sitio web y cierta información personal de nuestros usuarios. Solo
								requerimos de ti la cantidad mínima de información personal necesaria. Esta sección brinda detalles.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#what-information-OverSOS-does-not-collect">Qué información no recopila OverSOS</a>
							</td>
							<td>No recopilamos información de niños menores de 13 años y no recopilamos datos confidenciales.</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#how-we-share-the-information-we-collect">Cómo compartimos la información que recopilamos</a>
							</td>
							<td>
								Te proporcionamos formas de acceder, modificar o eliminar la información de tu perfil. También puedes ponerte en contacto con soporte
								para obtener más ayuda.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#how-you-can-access-and-control-the-information-we-collect">
									Cómo puedes acceder y controlar la información que recopilamos
								</a>
							</td>
							<td>
								Utilizamos cookies para la funcionalidad general de nuestro sitio web, y utilizamos una pequeña cantidad de servicios de seguimiento y
								análisis en algunas partes de nuestro sitio. Ofrecemos una página que hace que esto sea muy transparente. Consulte esta sección para
								obtener más información.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#our-use-of-cookies-and-tracking">Nuestro uso de cookies y seguimiento</a>
							</td>
							<td>
								Tomamos todas las medidas razonablemente necesarias para proteger la confidencialidad, integridad y disponibilidad de tu información
								personal en OverSOS y para proteger la resistencia de nuestros servidores cuando alojan tu información.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#how-OverSOS-secures-your-information">Cómo OverSOS protege tu información</a>
							</td>
							<td>OverSOS cumple con el Reglamento general de protección de datos. Consulte esta sección para obtener información más específica.</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#OverSOSs-global-privacy-practices">Prácticas de privacidad global de OverSOS</a>
							</td>
							<td>OverSOS cumple con el Reglamento general de protección de datos. Consulte esta sección para obtener información más específica.</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#how-we-respond-to-compelled-disclosure">Cómo respondemos a la divulgación obligatoria</a>
							</td>
							<td>
								Podemos compartir tu información en respuesta a una orden judicial, citación u otra acción judicial, o si la divulgación es necesaria
								para proteger nuestros derechos o los derechos del público en general. Nos esforzamos por la transparencia y le notificaremos cuando
								sea posible.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#how-we-and-others-communicate-with-you">Cómo nosotros, y otros, nos comunicamos contigo</a>
							</td>
							<td>
								Nos comunicamos contigo por correo electrónico. Puedes controlar la forma en que nos contactamos contigo en la configuración de tu
								cuenta.
							</td>
						</tr>
						<tr>
							<td><a style="text-decoration: none" href="#resolving-complaints">Resolución de quejas</a></td>
							<td>
								En el improbable caso de que no podamos resolver un problema de privacidad de forma rápida y completa, proporcionamos una vía de
								resolución de disputas a través de árbitros externos.
							</td>
						</tr>
						<tr>
							<td>
								<a style="text-decoration: none" href="#changes-to-our-Privacy-Statement">Cambios a nuestra Declaración de privacidad</a>
							</td>
							<td>
								Trataremos de notificarte de los cambios materiales a esta Declaración de Privacidad 30 días antes de que dichos cambios entren en
								vigencia.
							</td>
						</tr>
						<tr>
							<td><a style="text-decoration: none" href="#contacting-OverSOS">Contactando OverSOS</a></td>
							<td>No dudes en contactarnos si tienes preguntas sobre nuestra Declaración de privacidad.</td>
						</tr>
						<tr>
							<td><a style="text-decoration: none" href="#license">Licencia</a></td>
							<td>
								Este Acuerdo está licenciado bajo la licencia
								<a style="text-decoration: none" href="https://creativecommons.org/publicdomain/zero/1.0/">Creative Commons Zero</a>.
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</div>
		<div class="my-10">
			<h2 id="what-information-OverSOS-collects-and-why" class="terms-header">¿Qué información recopila OverSOS y por qué?</h2>
			<div class="my-5">
				<h4 class="terms-subheader">Información de los navegadores web</h4>
				<p>
					Si sólo estás navegando por el sitio web, recopilamos la misma información básica que la mayoría de los sitios web. Utilizamos tecnologías
					comunes de Internet, como cookies y registros del servidor web. Esto es algo que recopilamos de todos, tengan o no una cuenta.
				</p>
				<p>
					La información que recopilamos sobre todos los visitantes de nuestro sitio web incluye el tipo de navegador del visitante, la preferencia de
					idioma, el sitio de referencia, los sitios web adicionales solicitados y la fecha y hora de cada solicitud de visitante, entre otras.
					También recopilamos información potencialmente de identificación personal como direcciones de Protocolo de Internet (IP).
				</p>
				<div class="my-3">
					<h5>¿Por qué recopilamos esta información?</h5>
					<p>
						Recopilamos esta información para comprender mejor cómo los visitantes de nuestro sitio web usan el servicio y para monitorear y proteger
						la seguridad del sitio web.
					</p>
				</div>
			</div>
			<div class="my-5">
				<h4 class="terms-subheader">Información de usuarios con cuentas</h4>
				<p>
					Si creas una cuenta, necesitamos información básica en el momento de la creación de la cuenta. La crearás con tu propio nombre de usuario y
					contraseña, y te pediremos una dirección de correo electrónico válida. También tienes la opción de proporcionarnos más información si lo
					deseas y esto puede incluir "Información personal del usuario".
				</p>
				<p>
					La "información personal del usuario" es cualquier información sobre uno de nuestros usuarios que podría, sola o junto con otra información,
					identificarlo personalmente. Información como un nombre de usuario y contraseña, una dirección de correo electrónico, un nombre real y una
					fotografía son ejemplos de "Información personal del usuario". La información personal del usuario incluye datos personales tal como se
					definen en el Reglamento general de protección de datos.
				</p>
				<p>
					La información personal del usuario no incluye información agregada que no sea de identificación personal. Podemos utilizar información
					agregada que no sea de identificación personal para operar, mejorar y optimizar nuestro sitio web y servicio.
				</p>
				<div class="my-3">
					<h5>¿Por qué recopilamos esta información?</h5>
					<ul>
						<li>
							Necesitamos tu información personal de usuario para crear tu cuenta y para proporcionar los servicios que solicitas, incluso para
							proporcionar el servicio de OverSOS o para responder a solicitudes de soporte.
						</li>
						<li>Utilizamos tu información personal de usuario, específicamente tu nombre de usuario, para identificarte en OverSOS.</li>
						<li>La usamos para completar tu perfil y compartir ese perfil con otros usuarios si así lo solicitas.</li>
						<li>
							Usaremos tu dirección de correo electrónico para comunicarnos contigo, si has dicho que está bien, y sólo por las razones por las que
							has dicho que está bien. Consulta nuestra sección sobre comunicación por correo electrónico para obtener más información.
						</li>
						<li>
							Utilizamos información personal del usuario y otros datos para hacer recomendaciones para ti. Por ejemplo, cuando completas una encuesta
							de interés en la creación de la cuenta, aprendemos de ella, así como de tu comportamiento público en OverSOS, como las ofertas a las que
							solicitas y te recomendamos otras ofertas similares. Estas recomendaciones son decisiones automatizadas, pero no tienen impacto legal
							sobre tus derechos.
						</li>
						<li>
							Utilizamos tu información personal de usuario para fines internos, como mantener registros por razones de seguridad, con fines de
							capacitación y para documentación legal.
						</li>
						<li>
							Utilizamos información personal del usuario para gestionar y facilitar la interacción en la bolsa de empleo de OverSOS, como por ejemplo
							información sobre los estudios o documentos tales como resumen de notas, carta de motivación o currículum, entre otros posibles.
						</li>
						<li>
							Limitamos nuestro uso de tu Información personal del usuario a los fines enumerados en esta Declaración de privacidad. Si necesitamos
							usar tu información personal de usuario para otros fines, te pediremos tu permiso primero. Siempre puedes ver qué información tenemos,
							cómo la estamos usando y qué permisos nos has otorgado en tu perfil de usuario.
						</li>
					</ul>
				</div>
				<div class="my-3">
					<h5>Nuestra base legal para procesar información</h5>
					<p>
						Según ciertas leyes internacionales (incluido el GPDR), OverSOS debe notificarte sobre la base legal sobre la que procesamos la
						Información personal del usuario. OverSOS procesa la información personal del usuario sobre las siguientes bases legales:
					</p>
					<ul>
						<li>
							Cuando creas una cuenta en OverSOS, proporcionas tu nombre de usuario y una dirección de correo electrónico. Requerimos esos elementos
							de datos para que firmes el Acuerdo de Términos de Servicio con nosotros, y procesamos esos elementos sobre la base de la ejecución de
							ese contrato. También procesamos tu nombre de usuario y dirección de correo electrónico en otras bases. Puede haber otros elementos de
							datos que debemos recopilar y procesar sobre la base de la ejecución de ese contrato. OverSOS no recopila ni procesa un número de
							tarjeta de crédito, pero nuestro procesador de pagos externo sí.
						</li>
						<li>
							Cuando completas la información en tu perfil de usuario, tienes la opción de proporcionar información personal de usuario, como tu
							nombre completo, un avatar que puede incluir una fotografía y una URL a un sitio web de terceros. Tienes la opción de configurar una
							dirección de correo electrónico públicamente visible aquí. Procesamos esta información sobre la base del consentimiento. Toda esta
							información es completamente opcional y tienes la capacidad de acceder, modificar y recuperarla en cualquier momento.
						</li>
						<li>
							En general, el resto del procesamiento de la información personal que realizamos es necesario para los fines de nuestros intereses
							legítimos. Por ejemplo, por razones de seguridad, debemos mantener registros de las direcciones IP que acceden al sitio web, y para
							responder al proceso legal, debemos mantener registros de los usuarios que han enviado y recibido avisos de eliminación de DMCA.
						</li>
						<li>
							Si deseas solicitar el borrado de datos que procesamos sobre la base del consentimiento u objetar nuestro procesamiento de información
							personal, puedes enviarnos un mensaje mediante el formulario de contacto.
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="my-10">
			<h2 id="what-information-OverSOS-does-not-collect" class="terms-header">Qué información no recopila OverSOS</h2>
			<p>
				No recopilamos intencionalmente información personal confidencial, como números de seguro social, datos genéticos, información de salud o
				información religiosa. Aunque OverSOS no solicita ni recopila intencionalmente información personal confidencial, sabemos que podrías
				almacenar este tipo de información en tu cuenta. Si almacenas información personal confidencial en nuestros servidores, eres responsable de
				cumplir con los controles reglamentarios con respecto a esos datos.
			</p>
			<p>
				Si eres un niño menor de 13 años, es posible que no tengas una cuenta en OverSOS. OverSOS no recopila a sabiendas información de nuestro
				contenido ni lo dirige específicamente a niños menores de 13 años. Si nos enteramos o tenemos motivos para sospechar que eres un usuario menor
				de 13 años, lamentablemente tendremos que cerrar tu cuenta. No queremos disuadirte de que compartas tus conocimientos y aprendas, pero esas
				son las reglas. Consulte nuestros Términos de servicio para obtener información sobre la cancelación de la cuenta. Otros países pueden tener
				diferentes límites de edad mínima, y si estás por debajo de la edad mínima para otorgar consentimiento para la recopilación de datos en tu
				país, no puedes usar OverSOS sin obtener el consentimiento de tus padres o tutores legales.
			</p>
			<p>
				No recopilamos intencionalmente información personal del usuario que se almacena en tus mensajes u otras entradas de contenido de forma libre.
				La información de tus mensajes te pertenece a ti y tú eres responsable de ella, así como de asegurarte de que tu contenido cumpla con nuestros
				Términos de servicio.
			</p>
			<p>
				No recopilamos intencionalmente Información personal del usuario que se almacena en las carpetas de las asignaturas que estás visitando o
				cargando. Cualquier información personal dentro de la carpeta de una asignatura es responsabilidad del usuario que subió el contenido.
			</p>
			<div class="my-5">
				<h4 class="terms-subheader">Contenido de las carpetas de la asignatura</h4>
				<p>
					Cualquiera que tenga acceso a las carpetas de una asignatura determinada puede ver su contenido. Si has incluido información privada o
					confidencial, como direcciones de correo electrónico o contraseñas, esa información podría ser indexada por motores de búsqueda o utilizada
					por terceros. Además, aunque generalmente no buscamos contenido en las carpetas, podemos escanearlas en busca de ciertos tokens o firmas de
					seguridad, o en busca de malware activo conocido.
				</p>
				<p>Consulta más información sobre la información personal del usuario en las carpetas de asignatura.</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="how-we-share-the-information-we-collect" class="terms-header">Cómo compartimos la información que recopilamos</h2>
			<p>
				Compartimos la información personal del usuario con tu permiso, para que podamos realizar los servicios que has solicitado o comunicarnos en
				tu nombre. Además, puedes indicar, a través de tus acciones en OverSOS, que estás dispuesto a compartir tu información personal de usuario.
				Respetaremos tus elecciones.
			</p>
			<p>
				No compartimos, vendemos, alquilamos ni intercambiamos Información personal del usuario con terceros para sus fines comerciales, excepto
				cuando tú nos lo hayas indicado específicamente.
			</p>
			<p>
				No divulgamos información personal del usuario fuera de OverSOS, excepto en las situaciones enumeradas en esta sección o en la siguiente
				sección sobre divulgación obligatoria.
			</p>
			<p>
				Compartimos cierta información de identificación no personal agregada con otros sobre cómo nuestros usuarios, colectivamente, usan OverSOS, o
				cómo nuestros usuarios responden a nuestras otras ofertas, como nuestras conferencias o eventos.
			</p>
			<p>
				Compartimos la información personal del usuario con un número limitado de proveedores externos que la procesan en nuestro nombre para
				proporcionar o mejorar nuestro servicio, y que han aceptado restricciones de privacidad similares a nuestra propia Declaración de privacidad
				mediante la firma de acuerdos de protección de datos. Nuestros proveedores realizan servicios tales como procesamiento de pagos, emisión de
				boletos de soporte al cliente, transmisión de datos de red, control de contenido de carpetas y otros servicios similares. Cuando transferimos
				tus datos a nuestros proveedores, seguimos siendo responsables de ello. Si bien OverSOS procesa toda la información personal del usuario en
				España, nuestros proveedores externos pueden procesar datos fuera de España. Si deseas saber quiénes son nuestros proveedores externos,
				consulta nuestra página en
				<router-link :to="{ name: 'PoliciesCookies' }"> Subprocessors </router-link>
				.
			</p>
			<p>
				Compartimos información de identificación no personal agregada con terceros. Por ejemplo, compartimos el número de miembros de una asignatura.
			</p>
			<p>
				Podemos compartir información personal del usuario si participamos en una fusión, venta o adquisición. Si ocurre cualquier cambio de
				propiedad, nos aseguraremos de que sea bajo términos que preserven la confidencialidad de la Información personal del usuario, y te
				notificaremos en nuestro sitio web o por correo electrónico antes de cualquier transferencia de tu Información personal del usuario. La
				organización que reciba cualquier Información personal del usuario deberá cumplir con las promesas que hayamos hecho en nuestra Declaración de
				privacidad o en nuestros Términos de servicio.
			</p>
			<p>
				Compartimos la información personal del usuario en caso de que el usuario haya aplicado a una oferta en nuestra bolsa de empleo. En ese
				escenario, la información se compartirá con la empresa encargada de gestionar dicha oferta de empleo. Si el usuario accede a compartir su
				perfil en OverSOS Talent Pool, entonces todas las empresas con acceso a este servicio podrán visualizar su perfil (Estudios, Descripción,
				Intereses, Idiomas, Aptitudes, Proyectos, etc.) y sus documentos tales como CV, Expediente o Carta de motivación que el usuario haya decidido
				marcar como principales.
			</p>
			<div class="my-5">
				<h4 class="terms-subheader">Información pública en OverSOS</h4>
				<p>
					Casi nada de OverSOS está orientado al público. Pero si tu contenido fuera público, terceras partes podrían acceder y usarlo de acuerdo con
					nuestros Términos de servicio. No vendemos ese contenido; es tuyo. Sin embargo, permitimos que terceros, como organizaciones de
					investigación o archivos, compilen información de OverSOS de cara al público.
				</p>
				<p>
					Tu información personal, asociada con tu contenido, podría ser recopilada por terceros en estas compilaciones de datos de OverSOS. Si no
					deseas que tu información personal aparezca en las compilaciones de datos de OverSOS de terceros, no pongas tu información personal a
					disposición del público. Actualmente configuramos el perfil de los usuarios como privado de forma predeterminada.
				</p>
				<p>
					Si deseas recopilar datos de OverSOS, debes cumplir con nuestros Términos de servicio con respecto al tratado y la privacidad, y solo puedes
					usar la Información personal pública que recopiles para el propósito para el que nuestro usuario la ha autorizado. Por ejemplo, cuando un
					usuario de OverSOS ha hecho pública una dirección de correo electrónico con el propósito de identificación y atribución, no utilices esa
					dirección de correo electrónico para publicidad comercial. Esperamos que protejas razonablemente toda la Información personal que hayas
					recopilado de OverSOS y que respondas con prontitud a las quejas, solicitudes de eliminación y solicitudes de "no contacto" de los usuarios
					de OverSOS o OverSOS.
				</p>
				<p>
					De manera similar, los proyectos en OverSOS pueden incluir información personal disponible públicamente recopilada como parte del proceso de
					colaboración. En el caso de que un proyecto de OverSOS contenga Información personal disponible públicamente que no pertenece a los usuarios
					de OverSOS, solo usaremos esa Información personal para el propósito limitado para el cual fue recopilada, y aseguraremos esa Información
					personal como lo haríamos con cualquier Informacion personal de usuario. Si tienes una queja sobre cualquier información personal en
					OverSOS, consulta nuestra sección sobre resolución de quejas.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="how-you-can-access-and-control-the-information-we-collect" class="terms-header">
				Cómo puedes acceder y controlar la información que recopilamos
			</h2>
			<p>
				Si ya eres usuario de OverSOS, puedes acceder, actualizar, modificar o eliminar tu información de perfil de usuario editando tu perfil de
				usuario o comunicándote con el Soporte de OverSOS. Puedes controlar la información que recopilamos sobre ti limitando la información que hay
				en tu perfil, actualizando la información desactualizada o comunicándote con el Soporte de OverSOS.
			</p>
			<div class="my-5">
				<h4 class="terms-subheader">Portabilidad de datos</h4>
				<p>
					Como usuario de OverSOS, siempre puedes llevar tus datos contigo. Puedes utilizar, por ejemplo, nuestras herramientas de Portabilidad de
					datos para descargar todos los datos que tenemos sobre ti.
				</p>
			</div>
			<div class="my-5">
				<h4 class="terms-subheader">Retención y eliminación de datos</h4>
				<p>
					Generalmente, OverSOS retendrá la información personal del usuario durante el tiempo que tu cuenta esté activa o según sea necesario para
					brindarte servicios.
				</p>
				<p>
					Podemos retener cierta información personal del usuario por tiempo indefinido, a menos que la elimines o solicites su eliminación. Por
					ejemplo, no eliminamos automáticamente las cuentas de usuarios inactivos, por lo que, a menos que elijas eliminar su cuenta, conservaremos
					la información de tu cuenta de forma indefinida.
				</p>
				<p>
					Si deseas cancelar tu cuenta o eliminar tu información personal de usuario, puedes hacerlo en tu perfil de usuario. Retendremos y usaremos
					tu información según sea necesario para cumplir con nuestras obligaciones legales, resolver disputas y hacer cumplir nuestros acuerdos, pero
					salvo requisitos legales, eliminaremos tu perfil completo (dentro de lo razonable) dentro de los 90 días. Puedes ponerte en contacto con el
					soporte de OverSOS para solicitar el borrado de los datos que procesamos sobre la base del consentimiento dentro de los 30 días.
				</p>
				<p>
					Después de que se haya eliminado una cuenta, permanecerán ciertos datos, como las contribuciones a las carpetas de asignaturas y los
					comentarios de otros usuarios. Sin embargo, eliminaremos o desidentificaremos tu información personal de tu perfil.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="our-use-of-cookies-and-tracking" class="terms-header">Nuestro uso de cookies y seguimiento</h2>
			<div class="my-5">
				<h4 class="terms-subheader">Cookies</h4>
				<p>
					OverSOS utiliza
					<router-link :to="{ name: 'PoliciesCookies' }"> cookies </router-link>
					para hacer que las interacciones con nuestro servicio sean fáciles y significativas. Utilizamos cookies (y tecnologías similares, como HTML5
					localStorage) para mantenerte conectado, recordar tus preferencias y proporcionar información para el desarrollo futuro de OverSOS. También
					utilizamos cookies para identificar un dispositivo, por razones de seguridad. Al usar nuestro sitio web, aceptas que podemos colocar este
					tipo de cookies en tu computadora o dispositivo. Si deshabilitas la capacidad de tu navegador o dispositivo para aceptar cookies, no podrás
					iniciar sesión ni utilizar los servicios de OverSOS.
				</p>
				<p>
					Proporcionamos una página web sobre cookies y seguimiento que describe las cookies que configuramos, las necesidades que tenemos de esas
					cookies y los tipos de cookies que son (temporales o permanentes). También enumera nuestros proveedores de servicios y análisis de terceros
					y detalla exactamente qué partes de nuestro sitio web les permitimos rastrear.
				</p>
			</div>
			<div class="my-5">
				<h4 class="terms-subheader">Seguimiento y analítica</h4>
				<p>
					Utilizamos una serie de proveedores de servicios y análisis externos para ayudarnos a evaluar el uso que hacen nuestros usuarios de OverSOS;
					compilar informes estadísticos sobre la actividad; y mejorar nuestro contenido y el rendimiento del sitio web. Solo utilizamos estos
					proveedores de análisis de terceros en ciertas áreas de nuestro sitio web, y todos ellos han firmado acuerdos de protección de datos con
					nosotros que limitan el tipo de información personal que pueden recopilar y el propósito para el cual pueden procesar la información.
					Además, utilizamos nuestro propio software de análisis interno para proporcionar funciones y mejorar nuestro contenido y rendimiento.
				</p>
				<p>
					Actualmente no respondemos a la señal de No rastrear de tu navegador, y no permitimos que terceros que no sean nuestros proveedores de
					análisis y servicios rastreen la actividad de los usuarios de OverSOS a lo largo del tiempo en OverSOS. No rastreamos tu actividad de
					navegación en línea en otros servicios en línea a lo largo del tiempo.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="how-OverSOS-secures-your-information" class="terms-header">Cómo OverSOS protege tu información</h2>
			<p>
				OverSOS toma todas las medidas razonablemente necesarias para proteger la información personal del usuario del acceso no autorizado,
				alteración o destrucción; mantener la precisión de los datos; y ayudar a garantizar el uso adecuado de la información personal del usuario.
			</p>
			<p>OverSOS aplica un programa escrito de información de seguridad. Nuestro programa:</p>
			<ul>
				<li>es apropiado para la naturaleza, el tamaño y la complejidad de las operaciones de OverSOS</li>
				<li>incluye respuesta a incidentes y procesos de notificación de violación de datos</li>
				<li>cumple con las leyes y regulaciones de seguridad de la información aplicables en las regiones geográficas donde OverSOS actúa</li>
			</ul>
			<p>
				En el caso de una violación de datos que afecte tu información personal del usuario, actuaremos de inmediato para mitigar el impacto de una
				violación y notificaremos a los usuarios afectados sin demora indebida.
			</p>
			<p>
				La transmisión de datos en OverSOS se cifra mediante SSH, HTTPS y SSL/TLS. Si bien nuestros datos no están cifrados en reposo, administramos
				nuestras propias jaulas y bastidores en los centros de datos de primer nivel con excelente seguridad física y de red, y cuando los datos se
				almacenan con un proveedor de almacenamiento externo, se cifran.
			</p>
			<p>
				Ningún método de transmisión, o método de almacenamiento electrónico, es 100% seguro. Por lo tanto, no podemos garantizar tu seguridad
				absoluta. Para obtener más información, consulta nuestras divulgaciones de seguridad.
			</p>
		</div>
		<div class="my-10">
			<h2 id="OverSOSs-global-privacy-practices" class="terms-header">Prácticas de privacidad global de OverSOS</h2>
			<p>
				Procesamos y almacenamos la información que recopilamos en España de acuerdo con esta Declaración de privacidad (nuestros subprocesadores
				pueden almacenar y procesar datos fuera de España). Sin embargo, entendemos que tenemos usuarios de diferentes países y regiones con
				diferentes expectativas de privacidad, e intentamos satisfacer esas necesidades incluso cuando España no tiene el mismo marco de privacidad
				que otros países.
			</p>
			<p>
				Ofrecemos el mismo estándar de protección de la privacidad, como se describe en esta Declaración de privacidad, a todos nuestros usuarios en
				todo el mundo, independientemente de su país de origen o ubicación, y estamos orgullosos de los niveles de notificación, elección,
				responsabilidad, seguridad, datos, integridad, acceso y recurso que brindamos. Hemos designado un asesor de privacidad y trabajamos arduamente
				para cumplir con las leyes de privacidad de datos aplicables en cualquier lugar donde operemos, y nuestro asesor de privacidad también actúa
				como nuestro oficial de protección de datos, parte de un equipo multifuncional que supervisa nuestros esfuerzos de cumplimiento de privacidad.
				Además, si nuestros proveedores o afiliados tienen acceso a la información personal del usuario, deben firmar acuerdos que les obliguen a
				cumplir con nuestras políticas de privacidad y con las leyes de privacidad de datos aplicables.
			</p>
			<p>En particular:</p>
			<ul>
				<li>
					OverSOS proporciona métodos claros de consentimiento informado e inequívoco en el momento de la recopilación de datos, cuando recopilamos
					sus datos personales utilizando el consentimiento como base.
				</li>
				<li>
					Recopilamos solo la cantidad mínima de datos personales necesarios para nuestros propósitos, a menos que elijas proporcionar más. Te
					recomendamos que nos proporciones únicamente la cantidad de datos que te sientas cómodo compartiendo.
				</li>
				<li>Te ofrecemos métodos sencillos para acceder, corregir o eliminar la Información personal del usuario que hemos recopilado.</li>
				<li>
					Proporcionamos a nuestros usuarios aviso, elección, responsabilidad, seguridad y acceso, y limitamos el propósito del procesamiento. También
					ofrecemos a nuestros usuarios un método de recurso y ejecución. Estos son los Principios del Escudo de Privacidad, pero también son solo
					buenas prácticas.
				</li>
			</ul>
		</div>
		<div class="my-10">
			<h2 id="how-we-respond-to-compelled-disclosure" class="terms-header">Cómo respondemos a la divulgación obligatoria</h2>
			<p>
				OverSOS puede divulgar información de identificación personal u otra información que recopilamos sobre ti a las fuerzas del orden público en
				respuesta a una citación válida, orden judicial u orden gubernamental similar, o cuando creemos de buena fe que la divulgación es
				razonablemente necesaria para proteger nuestra propiedad o derechos, o los de terceros o el público en general.
			</p>
			<p>
				Al cumplir con las órdenes judiciales y procesos legales similares, OverSOS se esfuerza por la transparencia. Cuando esté permitido, haremos
				un esfuerzo razonable para notificar a los usuarios sobre cualquier divulgación de su información, a menos que la ley o una orden judicial nos
				prohíban hacerlo, o en circunstancias excepcionales y exigentes.
			</p>
		</div>
		<div class="my-10">
			<h2 id="how-we-and-others-communicate-with-you" class="terms-header">Cómo nosotros, y otros, nos comunicamos contigo</h2>
			<p>
				Usaremos tu dirección de correo electrónico para comunicarnos contigo, si has dicho que está bien, y solo por las razones por las que has
				dicho que está bien. Por ejemplo, si te comunicas con nuestro equipo de Soporte con una solicitud, te responderemos por correo electrónico. Tú
				tienes control sobre cómo se usa y comparte tu dirección de correo electrónico en OverSOS. Puedes administrar tus preferencias de comunicación
				en tu perfil de usuario.
			</p>
			<p>
				Dependiendo de la configuración de tu correo electrónico, OverSOS puede enviar ocasionalmente correos electrónicos de notificación sobre
				cambios en una carpeta que estás siguiendo, nuevas funciones, solicitudes de comentarios, cambios importantes en las políticas u ofrecer
				soporte al cliente. También enviamos correos electrónicos de marketing, pero sólo con tu consentimiento, si optas por nuestra lista. Hay un
				enlace para cancelar la suscripción ubicado en la parte inferior de cada uno de los correos electrónicos de marketing que te enviamos. Ten en
				cuenta que no puedes optar por no recibir comunicaciones importantes de nuestra parte, como correos electrónicos de nuestro equipo de soporte
				o correos electrónicos del sistema, pero puedes configurar sus notificaciones en tu perfil.
			</p>
			<p>
				Nuestros correos electrónicos pueden contener una etiqueta de píxel, que es una imagen pequeña y clara que puede decirnos si has abierto o no
				un correo electrónico y cuál es tu dirección IP. Utilizamos esta etiqueta de píxel para que nuestro correo electrónico sea más eficaz para ti
				y para asegurarnos de que no te enviemos correos electrónicos no deseados.
			</p>
			<p>
				Compartimos la información sobre el estado de estudiante con los moderadores de tu facultad para que puedan comunicarse contigo. Si solicitas
				acceso verificado al contenido de una facultad, sus editores pueden verificar la información de estudiante.
			</p>
		</div>
		<div class="my-10">
			<h2 id="resolving-complaints" class="terms-header">Resolución de quejas</h2>
			<p>
				Si te preocupa la forma en que OverSOS maneja tu información personal del usuario, infórmanos de inmediato. Queremos ayudarte. Puedes
				contactarnos completando el formulario de contacto. También puedes enviarnos un correo electrónico directamente a nuestra dirección de correo
				electrónico con el asunto "Asuntos de privacidad". Responderemos con prontitud.
			</p>
			<div class="my-5">
				<h4 class="terms-subheader">Proceso de resolución de disputas</h4>
				<p>
					En el improbable caso de que surja una disputa entre ti y OverSOS con respecto a nuestro manejo de tu Información personal del usuario,
					haremos todo lo posible para resolverlo.
				</p>
			</div>
			<div class="my-5">
				<h4 class="terms-subheader">Arbitraje independiente</h4>
				<p>
					Bajo ciertas circunstancias limitadas, los individuos de la Unión Europea pueden invocar el arbitraje vinculante de Privacy Shield como
					último recurso si todas las otras formas de resolución de disputas no han tenido éxito. Para obtener más información sobre este método de
					resolución y su disponibilidad para ti, lee más sobre Privacy Shield. El arbitraje no es obligatorio; Es una herramienta que puedes utilizar
					si lo deseas.
				</p>
			</div>
		</div>
		<div class="my-10">
			<h2 id="changes-to-our-Privacy-Statement" class="terms-header">Cambios a nuestra Declaración de privacidad</h2>
			<p>
				Aunque es probable que la mayoría de los cambios sean menores, OverSOS puede cambiar la Declaración de privacidad de vez en cuando. Trateremos
				de proporcionar una notificación a los usuarios de los cambios materiales a esta Declaración de Privacidad a través de nuestro sitio web al
				menos 30 días antes de que el cambio entre en vigencia mediante la publicación de un aviso en nuestra página de inicio o enviando un correo
				electrónico a la dirección de correo electrónico principal especificada en tu cuenta OverSOS.
			</p>
		</div>
		<div class="my-10">
			<h2 id="contacting-OverSOS" class="terms-header">Contactando OverSOS</h2>
			<p>
				Las preguntas sobre la Declaración de privacidad de OverSOS o las prácticas de información deben dirigirse a nuestro formulario de contacto.
			</p>
		</div>
		<div class="my-10">
			<h2 id="license" class="terms-header">Licencia</h2>
			<p>
				Este Acuerdo está licenciado bajo la licencia
				<a style="text-decoration: none" href="https://creativecommons.org/publicdomain/zero/1.0/">Creative Commons Zero</a>.
			</p>
		</div>
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: `${this.$t('policies.title')} - ${this.$t('policies.privacy')}`,
		}
	},
}
</script>

<style scoped>
.terms-header {
	color: firebrick;
	border-bottom: 2px solid grey;
}
.terms-subheader {
	color: indianred;
}
</style>
